var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"p-5 text-xs"},[_c('div',{staticClass:"rounded-2xl bg-white p-5 py-13 shadow-md"},[_vm._m(0),_c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',{staticClass:"w-full",on:{"submit":function($event){$event.preventDefault();return _vm.createTicket($event)}}},[_c('div',{staticClass:"grid grid-cols-1 md:grid-cols-2 gap-4"},[_c('div',{staticClass:"mb-4"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"block mb-2 text-sm font-medium",attrs:{"for":"priority"}},[_vm._v("Prioritas*")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.ticketData.priority_id),expression:"ticketData.priority_id"}],class:("block border border-gray-200 w-full p-3 px-4 rounded text-sm bg-white text-gray-700 " + (errors[0] ? 'border-red-300 bg-red-50 placeholder-red-200 text-red-900 focus:border-red-300' : '')),attrs:{"id":"priority_id"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.ticketData, "priority_id", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":"","disabled":""}},[_vm._v("Pilih Prioritas")]),_c('option',{domProps:{"value":1}},[_vm._v("Low")]),_c('option',{domProps:{"value":2}},[_vm._v("Medium")]),_c('option',{domProps:{"value":3}},[_vm._v("High")]),_c('option',{domProps:{"value":4}},[_vm._v("Critical")])]),_c('span',{staticClass:"text-red-600 text-xs"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"mb-4"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"block mb-2 text-sm font-medium",attrs:{"for":"device_id"}},[_vm._v("Device")]),_c('v-select',{staticClass:"block border border-gray-300 w-full rounded text-sm bg-white text-gray-700",attrs:{"options":_vm.devices,"label":"name","reduce":function (device) { return device.id; },"searchable":true,"clearable":true},model:{value:(_vm.ticketData.device_id),callback:function ($$v) {_vm.$set(_vm.ticketData, "device_id", $$v)},expression:"ticketData.device_id"}}),_c('span',{staticClass:"text-red-600 text-xs"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"mb-4"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"block mb-2 text-sm font-medium",attrs:{"for":"customer_id"}},[_vm._v("Pelanggan*")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.ticketData.customer_id),expression:"ticketData.customer_id"}],staticClass:"block border border-gray-300 w-full p-3 rounded text-sm bg-white text-gray-700",attrs:{"id":"customer_id"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.ticketData, "customer_id", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},_vm._l((_vm.customers),function(customer){return _c('option',{key:customer.id,domProps:{"value":customer.id}},[_vm._v(" "+_vm._s(customer.company_name)+" ")])}),0),_c('span',{staticClass:"text-red-600 text-xs"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"mb-4"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"block mb-2 text-sm font-medium",attrs:{"for":"name"}},[_vm._v("Judul Keluhan*")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.ticketData.name),expression:"ticketData.name"}],staticClass:"block border border-gray-300 w-full p-3 rounded text-sm bg-white text-gray-700",attrs:{"id":"name","type":"text"},domProps:{"value":(_vm.ticketData.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.ticketData, "name", $event.target.value)}}}),_c('span',{staticClass:"text-red-600 text-xs"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"mb-4"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{staticClass:"block mb-2 text-sm font-medium",attrs:{"for":"description"}},[_vm._v("Deskripsi Keluhan*")]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.ticketData.description),expression:"ticketData.description"}],staticClass:"block border border-gray-300 w-full p-3 rounded text-sm bg-white text-gray-700",attrs:{"id":"description"},domProps:{"value":(_vm.ticketData.description)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.ticketData, "description", $event.target.value)}}}),_c('span',{staticClass:"text-red-600 text-xs"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('div',{staticClass:"mt-6 flex justify-end"},[_c('button',{staticClass:"py-2 px-8 rounded border border-gray-300 mr-2 hover:bg-gray-100",attrs:{"type":"button"},on:{"click":function($event){return _vm.$router.push('/tiket')}}},[_vm._v(" Cancel ")]),_c('button',{staticClass:"py-2 px-8 rounded bg-blue-500 text-white hover:bg-blue-700",attrs:{"disabled":invalid,"type":"submit"}},[_vm._v(" Tambah ")])])])]}}])})],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex items-center justify-between mb-6"},[_c('span',{staticClass:"font-semibold text-xl"},[_vm._v("Tambah Tiket")])])}]

export { render, staticRenderFns }