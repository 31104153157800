<template>
    <div class="p-5 text-xs">
        <!-- Card Container -->
        <div class="rounded-2xl bg-white p-5 py-13 shadow-md">
            <!-- Card Header -->
            <div class="flex items-center justify-between mb-6">
                <span class="font-semibold text-xl">Tambah Tiket</span>
            </div>

            <!-- Form Container -->
            <ValidationObserver v-slot="{ invalid }" ref="form">
                <form @submit.prevent="createTicket" class="w-full">
                    <!-- Grid Container with 5 Columns -->
                    <div class="grid grid-cols-1 md:grid-cols-2 gap-4">

                        <!-- Priority -->
                        <div class="mb-4">
                            <ValidationProvider rules="required" v-slot="{ errors }">
                                <label for="priority" class="block mb-2 text-sm font-medium">Prioritas*</label>
                                <select v-model="ticketData.priority_id" id="priority_id"
                                    :class="`block border border-gray-200 w-full p-3 px-4 rounded text-sm bg-white text-gray-700 ${errors[0] ? 'border-red-300 bg-red-50 placeholder-red-200 text-red-900 focus:border-red-300' : ''}`">
                                    <option value="" disabled>Pilih Prioritas</option>
                                    <option :value="1">Low</option>
                                    <option :value="2">Medium</option>
                                    <option :value="3">High</option>
                                    <option :value="4">Critical</option>
                                </select>
                                <span class="text-red-600 text-xs">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>

                        <!-- Device -->
                        <!-- <div class="mb-4">
                            <ValidationProvider rules="required" v-slot="{ errors }">
                                <label for="device_id" class="block mb-2 text-sm font-medium">Device</label>
                                <select v-model="ticketData.device_id" id="device_id"
                                    class="block border border-gray-300 w-full p-3 rounded text-sm bg-white text-gray-700">
                                    <option v-for="device in devices" :key="device.id" :value="device.id">
                                        {{ device.code }} - {{ device.name }}
                                    </option>
                                </select>
                                <span class="text-red-600 text-xs">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div> -->
                        <div class="mb-4">
                            <ValidationProvider rules="required" v-slot="{ errors }">
                                <label for="device_id" class="block mb-2 text-sm font-medium">Device</label>
                                <v-select v-model="ticketData.device_id" :options="devices" label="name"
                                    :reduce="device => device.id" :searchable="true" :clearable="true"
                                    class="block border border-gray-300 w-full rounded text-sm bg-white text-gray-700"></v-select>
                                <span class="text-red-600 text-xs">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>

                        <!-- Customer ID -->
                        <div class="mb-4">
                            <ValidationProvider rules="required" v-slot="{ errors }">
                                <label for="customer_id" class="block mb-2 text-sm font-medium">Pelanggan*</label>
                                <select v-model="ticketData.customer_id" id="customer_id"
                                    class="block border border-gray-300 w-full p-3 rounded text-sm bg-white text-gray-700">
                                    <option v-for="customer in customers" :key="customer.id" :value="customer.id">
                                        {{ customer.company_name }}
                                    </option>
                                </select>
                                <span class="text-red-600 text-xs">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>

                        <!-- Name -->
                        <div class="mb-4">
                            <ValidationProvider rules="required" v-slot="{ errors }">
                                <label for="name" class="block mb-2 text-sm font-medium">Judul Keluhan*</label>
                                <input v-model="ticketData.name" id="name" type="text"
                                    class="block border border-gray-300 w-full p-3 rounded text-sm bg-white text-gray-700" />
                                <span class="text-red-600 text-xs">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>

                        <!-- Ticket Status -->
                        <!-- <ValidationProvider rules="required" v-slot="{ errors }">
                            <label for="ticket_statuses_id" class="block mb-2">Status*</label>
                            <select v-model="ticketData.ticket_statuses_id" id="ticket_statuses_id"
                                :class="`block border border-gray-200 w-full p-2 px-4 rounded text-xs ${errors[0] ? 'border-red-300 bg-red-50 placeholder-red-200 text-red-900 focus:border-red-300' : ''}`">
                                <option :value="1">Open</option>
                                <option :value="2">Closed</option>
                                <option :value="3">Assigned</option>
                                <option :value="4">In Progress</option>
                                <option :value="5">On Hold</option>
                                <option :value="6">Pending</option>
                                <option :value="7">Escalated</option>
                                <option :value="8">Resolved</option>
                            </select>
                            <span class="text-red-600 text-xs">{{ errors[0] }}</span>
                        </ValidationProvider> -->

                        <!-- File -->
                        <!-- <ValidationProvider v-slot="{ errors }">
                            <label for="file" class="block mb-2">File</label>
                            <input type="file" id="file" @change="handleFileUpload"
                                :class="`block border border-gray-200 w-full p-2 px-4 rounded text-xs ${errors[0] ? 'border-red-300 bg-red-50 placeholder-red-200 text-red-900 focus:border-red-300' : ''}`" />
                            <span class="text-red-600 text-xs">{{ errors[0] }}</span>
                        </ValidationProvider> -->

                        <!-- Description -->
                        <div class="mb-4">
                            <ValidationProvider rules="required" v-slot="{ errors }">
                                <label for="description" class="block mb-2 text-sm font-medium">Deskripsi
                                    Keluhan*</label>
                                <textarea v-model="ticketData.description" id="description"
                                    class="block border border-gray-300 w-full p-3 rounded text-sm bg-white text-gray-700"></textarea>
                                <span class="text-red-600 text-xs">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>

                    </div>

                    <!-- Buttons -->
                    <div class="mt-6 flex justify-end">
                        <button type="button" @click="$router.push('/tiket')"
                            class="py-2 px-8 rounded border border-gray-300 mr-2 hover:bg-gray-100">
                            Cancel
                        </button>
                        <button :disabled="invalid" type="submit"
                            class="py-2 px-8 rounded bg-blue-500 text-white hover:bg-blue-700">
                            Tambah
                        </button>
                    </div>
                </form>
            </ValidationObserver>
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { ValidationProvider, ValidationObserver } from "vee-validate/dist/vee-validate.full";
import globalMixin from '../../mixin/global'
import moment from 'moment'
export default {
    data() {
        return {
            ticketData: {
                priority_id: '',
                customer_id: '',
                name: '',
                device_id: '',
                description: '',
                ticket_statuses_id: '',
                // file: null
            },
            customers: [], // Changed 'customer' to 'customers'
            devices: [],   // List of devices
            default_url: process.env.VUE_APP_IMAGES
        }
    },
    mixins: [globalMixin],
    methods: {
        async createTicket() {
            let formData = new FormData()
            Object.keys(this.ticketData).forEach(key => {
                if (this.ticketData[key] !== null && this.ticketData[key] !== '') {
                    formData.append(key, this.ticketData[key])
                }
            })
            try {
                const response = await axios.post('v1/tiket/create', formData, this.config)
                this.$snack.success({
                    text: 'Ticket created successfully!'
                })
                this.$router.push('/tiket')
            } catch (error) {
                if (error.response && error.response.status === 400) {
                    this.$refs.form.setErrors(error.response.data.message)
                }
                this.$snack.error({
                    text: 'Failed to create ticket'
                })
            }
        },
        fetchCustomers() {
            this.axios.get('v1/customer', this.config)
                .then((ress) => {
                    this.customers = ress.data.rows  // Changed 'customer' to 'customers'
                })
        },
        fetchDevices() {
            this.axios.get('v1/device', this.config)
                .then((ress) => {
                    this.devices = ress.data.rows
                })
        },
    },
    computed: {
        ...mapGetters({
            token: 'auth/token',
        }),
    },
    created() {
        this.fetchCustomers();  // Fetch customers when the component is created
        this.fetchDevices();    // Fetch devices when the component is created
    },
    components: {
        ValidationProvider, ValidationObserver
    }
}
</script>
